<template>
  <div class="popup_wrap">
    <div class="print_content">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentPrintPop',
  props: {
    printHtml: {
      type: String,
      default: ''
    }
  },
  created () {
    const parent = window.opener

    const html = parent.document.querySelector('#pdf_area').innerHTML
    this.$nextTick(() => {
      document.querySelector('.print_content').innerHTML = html
      parent.document.querySelector('#pdf_area').innerHTML = ''
      window.print()
    })
  },
  methods: {
  }
}
</script>

<style scoped>
  /* .print_content {width: 96%; border: none; border-radius: inherit; padding:10px !important; margin: 20px auto 20px !important;}
  .popup_wrap {border-radius: none !important; box-shadow: none !important; padding: 20px;} */
  img {width: 100%; }
</style>
